// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import CrownOutlined from '@ant-design/icons/es/icons/CrownOutlined';
import TableOutlined from '@ant-design/icons/es/icons/TableOutlined';
import BarsOutlined from '@ant-design/icons/es/icons/BarsOutlined';
import UploadOutlined from '@ant-design/icons/es/icons/UploadOutlined'
  export default {
    SmileOutlined,
CrownOutlined,
TableOutlined,
BarsOutlined,
UploadOutlined
  }