// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/api/auth/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/auth/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(
  body: API.LoginParams,
  options?: {
    [key: string]: any;
  },
) {
  return request<API.LoginResult>('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 新建规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/rule', {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** 获取商品列表 GET /goods */
export async function goods(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: {
    [key: string]: any;
  },
) {
  let result = await request<API.GoodsList>('/api/goods', {
    method: 'GET',
    params: {
      ...params,
      pageNum: params.current,
    },
    ...(options || {}),
  });
  result.success = result.status === 'success' ? true : false;
  return result;
}

/**
 * 新增商品
 * @param params
 * @param options
 */
export async function createGoods(
  params?: {},
  options?: {
    [key: string]: any;
  },
) {
  return request<API.GoodsList>('/api/goods/create', {
    method: 'POST',
    data: { ...params },
    ...(options || {}),
  });
}

/**
 * 根据商品ID，删除商品
 * @param id
 * @returns
 */
export async function deleteGoodsById(id: number) {
  return request('/api/goods/deleteById', {
    method: 'DELETE',
    params: {
      id,
    },
  });
}

/**
 * 根据商品ID，修改商品信息
 * @param params
 * @returns
 */
export async function updateById(params: {}) {
  return request('/api/goods/updateById', {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function getSystemSetting() {
  let result = await request<API.SystemSettingResponse>('/api/systemSetting', {
    method: 'GET',
  });
  if (result.status === 'success') {
    return result.data;
  } else {
    return [];
  }
}

export async function updateSystemSetting(
  params: {
    /** 系统配置项 value */
    key?: string;
    /** 系统配置项 value */
    value?: string;
  },
  options?: {
    [key: string]: any;
  },
) {
  let result = await request<API.SystemSettingResponse>('/api/systemSetting/update', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
  return result.status === 'success' ? true : false;
}
