// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import CrownOutlined from '@ant-design/icons/CrownOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import BarsOutlined from '@ant-design/icons/BarsOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined'

export default {
  SmileOutlined,
CrownOutlined,
TableOutlined,
BarsOutlined,
UploadOutlined
}
    